import React from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { SEO, Video } from 'components';
import { useInView } from 'react-intersection-observer';
import { Illustration } from '../images/about-us';
import { JoinOurTeam } from '../components/AboutUs';

const AboutUs = ({ data }) => {
  const { groupImage, images } = data;
  const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.25
      }
    }
  };

  const numberItem = {
    hidden: { opacity: 0, x: 32, rotate: 16 },
    visible: { opacity: 1, x: 0, rotate: 0 }
  };

  const listItem = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  const { ref, inView } = useInView({
    threshold: 0,
    delay: 250
  });

  return (
    <>
      <SEO
        pageSpecificTitle="About Us"
        pageSpecificDescription="An award winning agency working in the Healthcare and Pharmaceutical space."
        pageSpecificThumbnail="/thumbnails/omnichannel/thumbnail.jpg"
        pageSpecificThumbnailAlt="The team"
      />
      <HeaderImage
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 1 }}>
        <GatsbyImage
          image={getImage(groupImage)}
          alt="Agency X team standing outside 12 Hatch Street"
        />
      </HeaderImage>
      <Section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 1 }}>
        <h3>
          We are an award winning agency working in the Healthcare and Pharmaceutical space. We are
          a team of 20 professionals, are IPHA Code Compliant and Veeva trained and certified.
        </h3>
        <Banner>
          {images.edges.map((image, index) => (
            <GatsbyImage image={getImage(image.node)} alt={`image-${index}`} />
          ))}
          <br />
        </Banner>
      </Section>
      <Section>
        <h2>Our Story So Far</h2>
        <Video
          style={{ marginBottom: '1.25rem' }}
          title="Our Story So Far"
          url="https://player.vimeo.com/video/910868960"
        />
      </Section>
      <Section
        ref={ref}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 1 }}>
        <h2>Why Choose Us?</h2>
        <AnimatePresence>
          <Body>
            {inView ? (
              <Numbers variants={container} initial="hidden" animate="visible">
                <div>
                  <Number key={1} variants={numberItem}>
                    1
                  </Number>
                  <motion.p key={2} variants={listItem}>
                    committed team from all corners of the globe, bringing a diverse and
                    multicultural approach to our work
                  </motion.p>
                </div>
                <div>
                  <Number key={3} variants={numberItem}>
                    15+
                  </Number>
                  <motion.p key={4} variants={listItem}>
                    years as a specialist Healthcare and Pharmaceutical agency
                  </motion.p>
                </div>
                <div>
                  <Number key={5} variants={numberItem}>
                    10
                  </Number>
                  <motion.p key={6} variants={listItem}>
                    Key Agency Services
                  </motion.p>
                </div>
                <div>
                  <Number key={7} variants={numberItem}>
                    20
                  </Number>
                  <motion.p key={8} variants={listItem}>
                    talented professionals from designers, to developers, to strategists and live
                    stream engineers...
                  </motion.p>
                </div>
              </Numbers>
            ) : (
              <div />
            )}
            <AnimatedSVG initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <Illustration inView={inView} />
            </AnimatedSVG>
          </Body>
        </AnimatePresence>
      </Section>
      <Section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 1 }}>
        <JoinOurTeam />
      </Section>
    </>
  );
};

const Section = styled(motion.section)`
  width: 100%;
  height: auto;
  max-width: 90rem;
  padding: 2rem 0;
  margin: 0 auto;

  > div {
    grid-column: 2/3;
  }
  > h2 {
    font-size: 2.25rem;
    grid-column: 2/3;
    margin-bottom: 3rem;
    text-align: center;
  }
  > h3 {
    font-size: 1.75rem;
    font-weight: 400;
    grid-column: 2/3;
    margin-bottom: 3rem;
  }
  > p {
    font-size: 1.25rem;
    line-height: 1.3em;
    grid-column: 2/3;
    margin-bottom: 3rem;
  }
  @media (min-width: 48rem) {
    padding: 3rem 0;
    > h2 {
      font-size: 3rem;
    }
    > h3 {
      font-size: 2rem;
    }
  }
`;

const HeaderImage = styled(motion.div)`
  width: 100%;
  height: auto;
  max-width: 90rem;
  padding: 6rem 0 0 0;
  margin: 0 auto;
  @media (min-width: 48rem) {
    padding: 6rem 3rem 0 3rem;
  }
`;

const Banner = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(6, 1fr);
  width: calc(100% + 2.5rem);
  margin-left: -1.25rem;
  .gatsby-image-wrapper {
    grid-column: span 4;
    grid-row: span 3;
    &:nth-of-type(1) {
      grid-column: span 6;
      grid-row: span 2;
    }
    &:nth-of-type(3) {
      grid-column: 1 / 7;
      grid-row: 3 / 5;
    }
    &:nth-of-type(4) {
      grid-column: 1 / 7;
      grid-row: 5 / 7;
    }
  }
  @media (min-width: 48rem) {
    grid-template-rows: repeat(2, 1fr);
    width: 100%;
    margin-left: 0;
    .gatsby-image-wrapper {
      grid-column: span 2;
      grid-row: span 2;
      &:nth-of-type(1) {
        grid-column: span 4;
      }
      &:nth-of-type(3) {
        grid-column: 7 / 9;
        grid-row: 1 / 2;
      }
      &:nth-of-type(4) {
        grid-column: 7 / 9;
        grid-row: 2 / 3;
      }
    }
  }
`;

const Body = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  svg {
    width: 100%;
  }
  @media (min-width: 64rem) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Numbers = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  h1 {
    font-size: 5rem;
    font-weight: 900;
    line-height: 1;
  }
  p {
    font-size: 1rem;
  }
  div {
    align-items: center;
    display: flex;
    gap: 1.5rem;
    &:nth-of-type(even) {
      h1 {
        color: var(--ax-gold);
      }
    }
  }
  @media (min-width: 48rem) {
    gap: 2rem;
    h1 {
      font-size: 7.5rem;
    }
    p {
      font-size: 1.25rem;
    }
  }
`;

const Number = styled(motion.h1)``;

const AnimatedSVG = styled(motion.div)`
  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
  @keyframes pulse {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
  @keyframes tick {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(-20.375c0deg);
    }
  }
  path {
    transform-box: fill-box;
    transform-origin: center;
  }
`;

export const Query = graphql`
  {
    groupImage: file(relativePath: { eq: "about-us/main-group.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
      }
    }
    images: allFile(
      filter: { relativeDirectory: { eq: "about-us/banner" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG, formats: AUTO, quality: 90)
          }
        }
      }
    }
  }
`;

export default AboutUs;
