import * as React from 'react';
const SvgTopRightStars = (props) => (
  <svg viewBox="0 0 259 196" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m13.754 102.634 57.992-12.22-6.444-48.072L90.2 70.089l44.148-35.312-9.531 41.929 57.442-1.035-61.451 32.006 28.721 25.039-36.919-10.466-16.487 56.565-17.551-49.443-30.785 17.245 14.088-32.991-48.121-10.992Z"
      fill="#F096F9"
      style={{ animation: "turn 4s linear alternate infinite" }}
    />
    <path
      d="M197.931 53.307c1.782-4.889-.736-10.297-5.625-12.08a9.421 9.421 0 0 0-12.079 5.626c-1.782 4.889.736 10.297 5.625 12.08a9.421 9.421 0 0 0 12.079-5.626Z"
      fill="#EA495D"
    />
    <path
      d="m219.546 91.894 2.395-17.168-4.296 16.793c-2.622 10.259-12.941 17.272-23.426 15.895l-16.512-2.161 16.098 4.261c10.216 2.702 17.105 13.104 15.648 23.592l-2.394 17.168 4.296-16.793c2.621-10.259 12.941-17.272 23.426-15.895l16.512 2.161-16.098-4.261c-10.217-2.701-17.105-13.104-15.649-23.592Z"
      fill="#000"
      style={{ animation: "spin 4s linear reverse infinite" }}
    />
  </svg>
);
export default SvgTopRightStars;
