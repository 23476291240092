import * as React from 'react';
const SvgBottomLeftStars = (props) => (
  <svg viewBox="0 0 133 102" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M103.931 35.307c1.783-4.889-.736-10.297-5.625-12.08a9.423 9.423 0 1 0 5.625 12.08Z"
      fill="#8D2882"
    />
    <path
      d="M59.398 21.906 68.177 0 57.02 20.795C50.2 33.508 34.337 39.41 21.004 34.175L0 25.933l19.775 10.877c12.546 6.907 18.193 22.896 12.827 36.285L23.823 95 34.98 74.205C41.8 61.5 57.663 55.59 70.996 60.825L92 69.067 72.225 58.191c-12.546-6.907-18.193-22.896-12.827-36.285Z"
      fill="#F096F9"
      style={{ animation: "spin 4s linear reverse infinite" }}
    />
    <path d="M132.401 84.915 115.914 64 95 80.488l16.487 20.915 20.914-16.488Z" fill="#0095FF" style={{ animation: "tick 6s ease-in-out infinite" }}/>
  </svg>
);
export default SvgBottomLeftStars;
